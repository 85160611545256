import { useDispatch, useSelector } from "react-redux";
import {
  flipTheCard,
  setEngagementTimer,
  setIsEngTaken,
  setIsShowEndScreen,
  setIsShowEngResult,
  setLinkedActions,
} from "../../../../redux/slices/appSlice";
import { useEffect, useState } from "react";
import { useEngagementTakeMutation } from "../../../../services/api";

function useEngController() {
  const isEndScreen = useSelector(
    (state) => state.flipcard?.data?.flipCard?.endScreen?._id
  );
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);

  const dispatch = useDispatch();
  const linkedActions = useSelector(
    (state) => state.flipcard.data.flipCard?.linkedActions
  );

  const isEngTaken = useSelector((state) => state.app.isEngTaken);
  const isRecirculation = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType === "recirculation"
  );

  const ctas = useSelector((state) => state.flipcard.data.flipCard?.actions);
  const optionsData = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.optionsData
  );

  const isGatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const engagementId = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?._id
  );

  const handleFlip = () => {
    dispatch(flipTheCard());
  };

  const [selectedId, setSelectedId] = useState("");
  const calculateWidthPercentage = (takesCount) => {
    let localOptionsData = [...optionsData];

    const selectedIdIndex = localOptionsData?.findIndex(
      (item) => item.optionId === selectedId
    );

    if (selectedIdIndex != -1) {
      localOptionsData[selectedIdIndex] = {
        ...localOptionsData[selectedIdIndex],
        takesCount: localOptionsData[selectedIdIndex].takesCount + 1,
      };
    }

    const totalTakes = localOptionsData.reduce(
      (total, option) => total + option.takesCount,
      0
    );

    const percentage = (takesCount / totalTakes) * 100;
    return percentage || 0;
  };

  const [_ENGAGEMENT_TAKE] = useEngagementTakeMutation();

  const handleEngTake = async (_id) => {
    setSelectedId(_id);
    if (mode === "create") {
      return;
    }
    if (linkedActions?.length > 0) {
      handleEnglinkedActions(_id);
    }
    dispatch(setIsEngTaken(true));

    takeAPi(_id);

    if (!ctas?.length) {
      if (isEndScreen) {
        dispatch(setIsShowEndScreen(true));
      }
    }

    if (linkedActions?.length > 0) {
      dispatch(setEngagementTimer(100));
      if (!isGatedEngagement) {
        if (ctas?.length > 0 || isEndScreen) {
          handleFlip();
        }
      } else if (isRecirculation && isEngTaken) {
        if (ctas?.length > 0 || isEndScreen) {
          handleFlip();
          dispatch(setIsShowEngResult(true));
        }
      }
    } else {
      if (!isGatedEngagement) {
        handleTimer();
      }
    }
  };

  const handleXraytake = async (_id) => {
    if (mode === "create") {
      return;
    }
    dispatch(setIsEngTaken(true));
    takeAPi(_id);
  };

  const takeAPi = async (_id) => {
    const sentData = {
      engagementId: engagementId,
      optionId: _id,
    };

    await _ENGAGEMENT_TAKE(sentData);
  };

  const handleEnglinkedActions = (_id) => {
    const actions = [];
    const actionIds =
      linkedActions.find((item) => item.optionId === _id)?.actionIds || [];

    ctas.forEach((action) => {
      const isIncluded = actionIds?.includes(action._id);
      if (isIncluded) {
        actions.push(action);
      }
    });
    dispatch(setLinkedActions(actions));
  };

  const handleTimer = () => {
    let counter = 0;
    const intervalId = setInterval(() => {
      dispatch(setEngagementTimer(counter));
      counter += 1;
      if (counter > 100) {
        clearInterval(intervalId);
        if (ctas?.length > 0 || isEndScreen) {
          handleFlip();
        }
      }
    }, 30);
  };

  useEffect(() => {
    if (isGatedEngagement && isCtaTaken) {
      handleTimer();
      if (isRecirculation) {
        handleRecirculationActions();
      }
      if (isEndScreen) {
        dispatch(setIsShowEndScreen(true));
        return;
      }
    }
  }, [isGatedEngagement, isCtaTaken]);

  const handleRecirculationActions = () => {
    if (linkedActions?.length > 0) {
      return;
    }
    const allLinkActions = ctas?.filter(
      (data) => data?.actionData?.actionType === "link"
    );
    dispatch(setLinkedActions(allLinkActions || []));
  };

  return {
    handleEngTake,
    calculateWidthPercentage,
    handleXraytake,
    selectedId,
  };
}

export default useEngController;

import React from "react";
import { useSelector } from "react-redux";

function MoreQuestions({ remainingData, handleAddEngagementData, isTwoTheme }) {
  const language = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.language
  );

  if (!remainingData?.length) {
    return <div />;
  }

  return (
    <div
      className="moreQuestions"
      style={{
        padding: "var(--mpr-1-2)",
        paddingTop: isTwoTheme ? "0px" : "var(--mpr-1-2)",
      }}
    >
      <div className="moreQuestion">
        {remainingData?.map((data, index) => {
          return (
            <div
              key={index}
              className="xrayQuestion"
              style={{
                borderRadius: isTwoTheme ? "0px" : "var(--mpr-3)",
              }}
              onClick={() => handleAddEngagementData(data)}
            >
              <span
                className="xrayMoreQuestionText"
                style={{
                  textAlign: language === "he" ? "right" : "left",
                  width: "100%",
                }}
              >
                {data?.questionText}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MoreQuestions;

import React from "react";
import "./styles/eng.css";
import Message from "../utils/presentation/message/workflow/Message";
import { useSelector } from "react-redux";

function EngLayout({ children }) {
  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);
  const engagementTemplate = useSelector(
    (state) =>
      state.flipcard.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );

  return (
    <div
      className="bridgedCard"
      style={{
        borderRadius: outerBorderRadius,
        border:
          engagementTemplate === "two" ? "none" : "1px solid var(--fill-Color)",
      }}
    >
      <div
        className="eng"
        style={{
          backgroundColor:
            engagementTemplate === "two"
              ? "transparent"
              : "var(--background-Color)",
        }}
      >
        <div className="engBody">
          {children}
          <Message />
        </div>
      </div>
    </div>
  );
}

export default EngLayout;

import React from "react";
import { useSelector } from "react-redux";
import "../styles/poweredby.css";

function PoweredBy({ isShowCta }) {
  const darkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const poweredBy_TEXT = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );
  const poweredByBridged = useSelector(
    (state) => state.flipcard.data.config?.poweredByBridged
  );

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  if (poweredByBridged) {
    return (
      <p
        className={`PowerdBy ${isShowCta && "xrayBgBlur"} `}
        style={{
          backgroundColor: darkTheme ? "#2E2E2E" : "#F5F5F5",
          opacity: 1,
        }}
      >
        <span style={{ opacity: 0.5 }}>{poweredBy_TEXT} </span>
        <img
          alt="bridged"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://bridged.media?utm_source=card", "_blank");
          }}
          src={bridgedLogo}
        />
      </p>
    );
  }

  return <div />;
}

export default PoweredBy;

import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import useFeedbackHandler from "../controllers/useFeedbackHandler";
import "../styles/feedback.css";
import Divider from "../../../../../utils/components/Divider";

function FeedbackTwo({ isShowCta }) {
  const { rating, changeRating } = useFeedbackHandler();
  const feedback = useSelector(
    (state) => state.flipcard.data.config?.feedbackConfig
  );

  const darkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const disclaimer = useSelector(
    (state) => state.flipcard.data.config?.disclaimer
  );

  if (!disclaimer && !feedback?.isActive) return null;

  return (
    <div
      style={{
        paddingBottom: "var(--mpr-3)",
      }}
    >
      <div
        style={{
          backgroundColor: darkTheme ? "#2E2E2E" : "#F5F5F5",
        }}
      >
        <Divider type="primary" style={{ marginBottom: "var(--mpr-3)" }} />
        <div className="feedbackTwo">
          {disclaimer && <p className="feedbackTwoDisclaimer">{disclaimer}</p>}
          {feedback?.isActive && (
            <div className="ratings">
              <StarRatings
                rating={rating}
                starRatedColor="#ffa106"
                changeRating={changeRating}
                numberOfStars={5}
                name="rating"
                starDimension="15px"
                starSpacing="1px"
                starHoverColor="#ffa106"
                starEmptyColor="#D9D9D9"
              />
              <p>{feedback?.title || "Rate your experience"}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedbackTwo;

const US_Language = {
  engDesc_TEXT: "Choose the best option",
  poweredBy_TEXT: "Powered by",
  name_TEXT: "Name",
  enterFullname_TEXT: "Enter your full name",
  fullName_TEXT: "Full Name",
  email_TEXT: "Email",
  enterEmail_TEXT: "Enter your email",
  emailNotValid_TEXT: "Email is not valid!",
  newsletterSource_TEXT: "Where you want to receive your newsletter",
  back_TEXT: "Back",
  next_TEXT: "Next",
  termsAndConditions_TEXT: {
    main_TEXT: "you agree you've read and accepted our",
    service_TEXT: "Terms of Service",
    privacy_TEXT: "Privacy Policy",
  },
  readMore_TEXT: "Read More",
  signUp_TEXT: "Sign Up",
  submit_TEXT: "Submit",
  apis_TEXT: {
    error_TEXT: "An error has occurred! Please try again later.",
  },
  required_TEXT: "Required",
  message_TEXT: "Message",
  loginWithGoogle_TEXT: "Continue with Google",
  notSelected_TEXT: "Not selected",
  internetIssue_TEXT:
    "Failed to establish connection. Please check your network connection and try again.",
  connectionTimeOut_TEXT:
    "Connection timed out. Please check your network connection and try again.",
  errorOccured_TEXT: "An error has occurred. Please try again later.",
  copiedToClipBoard_TEXT: "Copied to clipboard!",
  googleSignInError_TEXT: "Error during Google Sign-In",
  thankYouForRegistration_TEXT: "Thank you for registering!",
  close_TEXT: "Close",
  swipeTheMarker: "Swipe the marker",
  ragLoaderTexts: [
    "Checking question and fetching relevant data...",
    "Generating response...",
    "Verifying response...",
  ],
  generateText: "Generate",
  answerText: "Answer",
  questionText: "Question",
  referenceArticle: "Reference Articles",
  enterQuestion: "Enter Question",
  suggestedQuestions: "Suggested Questions",
};

const ES_Language = {
  engDesc_TEXT: "Elige la mejor opción",
  poweredBy_TEXT: "Desarrollado por",
  name_TEXT: "Nombre",
  email_TEXT: "Correo electrónico",
  back_TEXT: "Volver",
  next_TEXT: "Siguiente",
  termsAndConditions_TEXT: {
    main_TEXT: "Al aceptar, confirmas que has leído y aceptado nuestros",
    service_TEXT: "Términos de servicio",
    privacy_TEXT: "Política de privacidad",
  },
  readMore_TEXT: "Leer más",
  signUp_TEXT: "Registrarse",
  submit_TEXT: "Enviar",
  apis_TEXT: {
    error_TEXT:
      "¡Se ha producido un error! Por favor, inténtalo de nuevo más tarde.",
  },
  required_TEXT: "Requerido",
  emailNotValid_TEXT: "¡El correo electrónico no es válido!",
  message_TEXT: "Mensaje",
  enterFullname_TEXT: "Ingresa tu nombre completo",
  fullName_TEXT: "Nombre completo",
  enterEmail_TEXT: "Ingresa tu correo electrónico",
  newsletterSource_TEXT: "Dónde quieres recibir tu boletín",
  loginWithGoogle_TEXT: "Continuar con Google",
  notSelected_TEXT: "No seleccionado",
  internetIssue_TEXT:
    "No se pudo establecer conexión. Por favor, verifica tu conexión de red e inténtalo de nuevo.",
  connectionTimeOut_TEXT:
    "Tiempo de conexión agotado. Por favor, verifica tu conexión de red e inténtalo de nuevo.",
  errorOccured_TEXT:
    "Se ha producido un error. Por favor, inténtalo de nuevo más tarde.",
  copiedToClipBoard_TEXT: "Copiado al portapapeles",
  googleSignInError_TEXT: "Error durante la autenticación con Google",
  thankYouForRegistration_TEXT: "¡Gracias por registrarte!",
  close_TEXT: "Cerrar",
  swipeTheMarker: "Desliza el marcador",
  ragLoaderTexts: [
    "Comprobando la pregunta y obteniendo datos relevantes...",
    "Generando respuesta...",
    "Verificando respuesta...",
  ],
  generateText: "Pregunta",
  answerText: "Respuesta",
  questionText: "Pregunta",
  referenceArticle: "Artículos de referencia",
  enterQuestion: "¿Qué quieres saber?",
  suggestedQuestions: "Preguntas Sugeridas",
};

const NL_Language = {
  engDesc_TEXT: "Kies de beste optie",
  poweredBy_TEXT: "Aangedreven door",
  name_TEXT: "Naam",
  email_TEXT: "E-mail",
  back_TEXT: "Terug",
  next_TEXT: "Volgende",
  termsAndConditions_TEXT: {
    main_TEXT: "Door akkoord te gaan, bevestig je dat je onze",
    service_TEXT: "Servicevoorwaarden",
    privacy_TEXT: "Privacybeleid",
  },
  readMore_TEXT: "Lees meer",
  signUp_TEXT: "Aanmelden",
  submit_TEXT: "Verzenden",
  apis_TEXT: {
    error_TEXT: "Er is een fout opgetreden! Probeer het later opnieuw.",
  },
  required_TEXT: "Verplicht",
  emailNotValid_TEXT: "E-mail is niet geldig!",
  message_TEXT: "Bericht",
  enterFullname_TEXT: "Voer je volledige naam in",
  fullName_TEXT: "Volledige naam",
  enterEmail_TEXT: "Voer je e-mailadres in",
  newsletterSource_TEXT: "Waar wil je je nieuwsbrief ontvangen",
  loginWithGoogle_TEXT: "Doorgaan met Google",
  notSelected_TEXT: "Niet geselecteerd",
  internetIssue_TEXT:
    "Kon geen verbinding maken. Controleer je netwerkverbinding en probeer het opnieuw.",
  connectionTimeOut_TEXT:
    "Verbindingstime-out. Controleer je netwerkverbinding en probeer het opnieuw.",
  errorOccured_TEXT: "Er is een fout opgetreden. Probeer het later opnieuw.",
  copiedToClipBoard_TEXT: "Gekopieerd naar het klembord!",
  googleSignInError_TEXT: "Fout tijdens aanmelden met Google",
  thankYouForRegistration_TEXT: "Bedankt voor de registratie!",
  close_TEXT: "Sluiten",
  swipeTheMarker: "Veeg de marker",
  ragLoaderTexts: [
    "Vraag controleren en relevante gegevens ophalen...",
    "Antwoord genereren...",
    "Antwoord verifiëren...",
  ],
  generateText: "Genereren",
  answerText: "Antwoord",
  questionText: "Vraag",
  referenceArticle: "Referentieartikelen",
  enterQuestion: "Voer vraag in",
  suggestedQuestions: "Voorgestelde Vragen",
};

const LV_Language = {
  engDesc_TEXT: "Izvēlies labāko variantu",
  poweredBy_TEXT: "Darbinās",
  name_TEXT: "Vārds",
  email_TEXT: "E-pasts",
  back_TEXT: "Atpakaļ",
  next_TEXT: "Nākamais",
  termsAndConditions_TEXT: {
    main_TEXT: "Piekrītot, jūs apstiprināt, ka esat izlasījis un pieņēmis mūsu",
    service_TEXT: "Pakalpojumu noteikumi",
    privacy_TEXT: "Privātuma politiku",
  },
  readMore_TEXT: "Lasīt vairāk",
  signUp_TEXT: "Reģistrēties",
  submit_TEXT: "Nosūtīt",
  apis_TEXT: {
    error_TEXT: "Radusies kļūda! Lūdzu, mēģiniet vēlreiz vēlāk.",
  },
  required_TEXT: "Nepieciešams",
  emailNotValid_TEXT: "E-pasts nav derīgs!",
  message_TEXT: "Ziņa",
  enterFullname_TEXT: "Ievadiet savu pilno vārdu",
  fullName_TEXT: "Pilns vārds",
  enterEmail_TEXT: "Ievadiet savu e-pasta adresi",
  newsletterSource_TEXT: "Kur vēlaties saņemt jaunumus",
  loginWithGoogle_TEXT: "Turpināt ar Google",
  notSelected_TEXT: "Nav atlasīts",
  internetIssue_TEXT:
    "Neizdevās izveidot savienojumu. Lūdzu, pārbaudiet savu tīkla savienojumu un mēģiniet vēlreiz.",
  connectionTimeOut_TEXT:
    "Savienojuma pārtraukums. Lūdzu, pārbaudiet savu tīkla savienojumu un mēģiniet vēlreiz.",
  errorOccured_TEXT: "Radusies kļūda. Lūdzu, mēģiniet vēlreiz vēlāk.",
  copiedToClipBoard_TEXT: "Nokopēts uz starpliktuvi!",
  googleSignInError_TEXT: "Kļūda, pieteicoties ar Google",
  thankYouForRegistration_TEXT: "Paldies par reģistrāciju!",
  close_TEXT: "Aizvērt",
  swipeTheMarker: "Velciet marķieri",
  ragLoaderTexts: [
    "Pārbaudot jautājumu un iegūstot atbilstošos datus...",
    "Atbildes ģenerēšana...",
    "Atbildes pārbaude...",
  ],
  generateText: "Ģenerēt",
  answerText: "Atbilde",
  questionText: "Jautājums",
  referenceArticle: "Atsauces raksti",
  enterQuestion: "Ievadiet jautājumu",
  suggestedQuestions: "Ieteiktie Jautājumi",
};

const UA_Language = {
  engDesc_TEXT: "Виберіть найкращий варіант",
  poweredBy_TEXT: "Працює на основі",
  name_TEXT: "Ім'я",
  email_TEXT: "Електронна пошта",
  back_TEXT: "Назад",
  next_TEXT: "Далі",
  termsAndConditions_TEXT: {
    main_TEXT: "Погоджуючись, ви підтверджуєте, що прочитали та прийняли наші",
    service_TEXT: "Умови надання послуг",
    privacy_TEXT: "Політику конфіденційності",
  },
  readMore_TEXT: "Детальніше",
  signUp_TEXT: "Зареєструватися",
  submit_TEXT: "Надіслати",
  apis_TEXT: {
    error_TEXT: "Сталася помилка! Будь ласка, спробуйте пізніше.",
  },
  required_TEXT: "Обов'язково",
  emailNotValid_TEXT: "Електронна пошта недійсна!",
  message_TEXT: "Повідомлення",
  enterFullname_TEXT: "Введіть ваше повне ім'я",
  fullName_TEXT: "Повне ім'я",
  enterEmail_TEXT: "Введіть вашу електронну адресу",
  newsletterSource_TEXT: "Де ви хочете отримувати свій бюлетень",
  loginWithGoogle_TEXT: "Продовжити з Google",
  notSelected_TEXT: "Не вибрано",
  internetIssue_TEXT:
    "Не вдалося встановити з'єднання. Будь ласка, перевірте ваше мережеве підключення та спробуйте ще раз.",
  connectionTimeOut_TEXT:
    "Час очікування підключення вийшов. Будь ласка, перевірте ваше мережеве підключення та спробуйте ще раз.",
  errorOccured_TEXT: "Сталася помилка. Будь ласка, спробуйте ще раз пізніше.",
  copiedToClipBoard_TEXT: "Скопійовано в буфер обміну!",
  googleSignInError_TEXT: "Помилка під час входу через Google",
  thankYouForRegistration_TEXT: "Дякуємо за реєстрацію!",
  close_TEXT: "Закрити",
  swipeTheMarker: "Проведіть маркер",
  ragLoaderTexts: [
    "Перевірка питання та отримання відповідних даних...",
    "Генерація відповіді...",
    "Перевірка відповіді...",
  ],
  generateText: "Генерувати",
  answerText: "Відповідь",
  questionText: "Питання",
  referenceArticle: "Статті для довідки",
  enterQuestion: "Введіть запитання",
  suggestedQuestions: "Запропоновані Питання",
};

const DA_Language = {
  engDesc_TEXT: "Vælg den bedste mulighed",
  poweredBy_TEXT: "Drevet af",
  name_TEXT: "Navn",
  enterFullname_TEXT: "Indtast dit fulde navn",
  fullName_TEXT: "Fulde navn",
  email_TEXT: "Email",
  enterEmail_TEXT: "Indtast din email",
  emailNotValid_TEXT: "Email er ikke gyldig!",
  newsletterSource_TEXT: "Hvor vil du modtage dit nyhedsbrev",
  back_TEXT: "Tilbage",
  next_TEXT: "Næste",
  termsAndConditions_TEXT: {
    main_TEXT:
      "Ved at acceptere bekræfter du, at du har læst og accepteret vores",
    service_TEXT: "Servicevilkår",
    privacy_TEXT: "Privatlivspolitik",
  },
  readMore_TEXT: "Læs mere",
  signUp_TEXT: "Tilmeld dig",
  submit_TEXT: "Indsend",
  apis_TEXT: {
    error_TEXT: "Der er opstået en fejl! Prøv venligst igen senere.",
  },
  required_TEXT: "Påkrævet",
  message_TEXT: "Besked",
  loginWithGoogle_TEXT: "Fortsæt med Google",
  notSelected_TEXT: "Ikke valgt",
  internetIssue_TEXT:
    "Kunne ikke oprette forbindelse. Tjek din netværksforbindelse og prøv igen.",
  connectionTimeOut_TEXT:
    "Forbindelsen blev afbrudt. Tjek din netværksforbindelse og prøv igen.",
  errorOccured_TEXT: "Der er opstået en fejl. Prøv venligst igen senere.",
  copiedToClipBoard_TEXT: "Kopieret til udklipsholder!",
  googleSignInError_TEXT: "Fejl under Google-log ind",
  thankYouForRegistration_TEXT: "Tak for din registrering!",
  close_TEXT: "Luk",
  swipeTheMarker: "Stryg markøren",
  ragLoaderTexts: [
    "Kontrollerer spørgsmål og henter relevante data...",
    "Genererer svar...",
    "Verificerer svar...",
  ],
  generateText: "Fragen",
  answerText: "Svar",
  questionText: "Spørgsmål",
  referenceArticle: "Referenceartikler",
  enterQuestion: "Indtast spørgsmål",
  suggestedQuestions: "Foreslåede Spørgsmål",
};

const CAT_Language = {
  engDesc_TEXT: "Tria la millor opció",
  poweredBy_TEXT: "Amb el suport de",
  name_TEXT: "Nom",
  enterFullname_TEXT: "Introdueix el teu nom complet",
  fullName_TEXT: "Nom complet",
  email_TEXT: "Correu electrònic",
  enterEmail_TEXT: "Introdueix el teu correu electrònic",
  emailNotValid_TEXT: "El correu electrònic no és vàlid!",
  newsletterSource_TEXT: "On vols rebre el teu butlletí",
  back_TEXT: "Enrere",
  next_TEXT: "Següent",
  termsAndConditions_TEXT: {
    main_TEXT: "acceptes que has llegit i acceptat els nostres",
    service_TEXT: "Termes del Servei",
    privacy_TEXT: "Política de Privacitat",
  },
  readMore_TEXT: "Llegir més",
  signUp_TEXT: "Registrar-se",
  submit_TEXT: "Enviar",
  apis_TEXT: {
    error_TEXT: "S'ha produït un error! Torna-ho a intentar més tard.",
  },
  required_TEXT: "Obligatori",
  message_TEXT: "Missatge",
  loginWithGoogle_TEXT: "Continuar amb Google",
  notSelected_TEXT: "No seleccionat",
  internetIssue_TEXT:
    "No s'ha pogut establir la connexió. Comprova la teva connexió de xarxa i torna-ho a intentar.",
  connectionTimeOut_TEXT:
    "S'ha esgotat el temps de connexió. Comprova la teva connexió de xarxa i torna-ho a intentar.",
  errorOccured_TEXT: "S'ha produït un error. Torna-ho a intentar més tard.",
  copiedToClipBoard_TEXT: "Copiat al porta-retalls!",
  googleSignInError_TEXT: "Error durant l'inici de sessió amb Google",
  thankYouForRegistration_TEXT: "Gràcies per registrar-te!",
  close_TEXT: "Tancar",
  swipeTheMarker: "Fes lliscar el marcador",
  ragLoaderTexts: [
    "Comprovant la pregunta i obtenint dades rellevants...",
    "Generant resposta...",
    "Verificant resposta...",
  ],
  generateText: "Generar",
  answerText: "Resposta",
  questionText: "Pregunta",
  referenceArticle: "Articles de referència",
  enterQuestion: "Introdueix la pregunta",
  suggestedQuestions: "Preguntes Sugerides",
};

const EST_Language = {
  engDesc_TEXT: "Vali parim valik",
  poweredBy_TEXT: "Toetab",
  name_TEXT: "Nimi",
  enterFullname_TEXT: "Sisestage oma täisnimi",
  fullName_TEXT: "Täisnimi",
  email_TEXT: "E-post",
  enterEmail_TEXT: "Sisestage oma e-post",
  emailNotValid_TEXT: "E-post ei ole kehtiv!",
  newsletterSource_TEXT: "Kuhu soovite oma uudiskirja saada",
  back_TEXT: "Tagasi",
  next_TEXT: "Järgmine",
  termsAndConditions_TEXT: {
    main_TEXT: "Nõustudes kinnitate, et olete lugenud ja nõustunud meie",
    service_TEXT: "Teenuse tingimused",
    privacy_TEXT: "Privaatsuspoliitika",
  },
  readMore_TEXT: "Loe rohkem",
  signUp_TEXT: "Registreeru",
  submit_TEXT: "Esita",
  apis_TEXT: {
    error_TEXT: "Ilmnes viga! Palun proovige hiljem uuesti.",
  },
  required_TEXT: "Kohustuslik",
  message_TEXT: "Sõnum",
  loginWithGoogle_TEXT: "Jätka Google'iga",
  notSelected_TEXT: "Pole valitud",
  internetIssue_TEXT:
    "Ühenduse loomine ebaõnnestus. Palun kontrollige oma võrguühendust ja proovige uuesti.",
  connectionTimeOut_TEXT:
    "Ühendus aegus. Palun kontrollige oma võrguühendust ja proovige uuesti.",
  errorOccured_TEXT: "Ilmnes viga. Palun proovige hiljem uuesti.",
  copiedToClipBoard_TEXT: "Kopeeritud lõikelauale!",
  googleSignInError_TEXT: "Google'i sisselogimise viga",
  thankYouForRegistration_TEXT: "Täname registreerimise eest!",
  close_TEXT: "Sulge",
  swipeTheMarker: "Pühkige markerit",
  ragLoaderTexts: [
    "Küsimuse kontrollimine ja asjakohaste andmete hankimine...",
    "Vastuse genereerimine...",
    "Vastuse kontrollimine...",
  ],
  generateText: "Genereeri",
  answerText: "Vastus",
  questionText: "Küsimus",
  referenceArticle: "Articles de Viiteartiklid",
  enterQuestion: "Sisestage küsimus",
  suggestedQuestions: "Soovitatud Küsimused",
};

const CZ_Language = {
  engDesc_TEXT: "Vyberte nejlepší možnost",
  poweredBy_TEXT: "Poháněno",
  name_TEXT: "Jméno",
  enterFullname_TEXT: "Zadejte své celé jméno",
  fullName_TEXT: "Celé jméno",
  email_TEXT: "E-mail",
  enterEmail_TEXT: "Zadejte svůj e-mail",
  emailNotValid_TEXT: "E-mail není platný!",
  newsletterSource_TEXT: "Kde chcete přijímat svůj newsletter",
  back_TEXT: "Zpět",
  next_TEXT: "Další",
  termsAndConditions_TEXT: {
    main_TEXT: "souhlasíte, že jste četli a přijali naše",
    service_TEXT: "Podmínky služby",
    privacy_TEXT: "Zásady ochrany osobních údajů",
  },
  readMore_TEXT: "Přečtěte si více",
  signUp_TEXT: "Zaregistrovat se",
  submit_TEXT: "Odeslat",
  apis_TEXT: {
    error_TEXT: "Došlo k chybě! Zkuste to prosím později.",
  },
  required_TEXT: "Povinné",
  message_TEXT: "Zpráva",
  loginWithGoogle_TEXT: "Pokračovat s Googlem",
  notSelected_TEXT: "Nevybráno",
  internetIssue_TEXT:
    "Nepodařilo se navázat spojení. Zkontrolujte prosím své síťové připojení a zkuste to znovu.",
  connectionTimeOut_TEXT:
    "Časové omezení připojení vypršelo. Zkontrolujte prosím své síťové připojení a zkuste to znovu.",
  errorOccured_TEXT: "Došlo k chybě. Zkuste to prosím později.",
  copiedToClipBoard_TEXT: "Zkopírováno do schránky!",
  googleSignInError_TEXT: "Chyba při přihlašování přes Google",
  thankYouForRegistration_TEXT: "Děkujeme za registraci!",
  close_TEXT: "Zavřít",
  swipeTheMarker: "Přejeďte značkou",
  ragLoaderTexts: [
    "Kontrola otázky a získávání relevantních dat...",
    "Generování odpovědi...",
    "Ověřování odpovědi...",
  ],
  generateText: "Generovat",
  answerText: "Odpověď",
  questionText: "Otázka",
  referenceArticle: "Referenční články",
  enterQuestion: "Zadejte otázku",
  suggestedQuestions: "Navrhované Otázky",
};

const DE_Language = {
  engDesc_TEXT: "Wählen Sie die beste Option",
  poweredBy_TEXT: "Unterstützt von",
  name_TEXT: "Name",
  enterFullname_TEXT: "Geben Sie Ihren vollständigen Namen ein",
  fullName_TEXT: "Vollständiger Name",
  email_TEXT: "E-Mail",
  enterEmail_TEXT: "Geben Sie Ihre E-Mail-Adresse ein",
  emailNotValid_TEXT: "E-Mail ist ungültig!",
  newsletterSource_TEXT: "Wo möchten Sie Ihren Newsletter erhalten",
  back_TEXT: "Zurück",
  next_TEXT: "Weiter",
  termsAndConditions_TEXT: {
    main_TEXT: "Sie stimmen zu, dass Sie unsere",
    service_TEXT: "Nutzungsbedingungen",
    privacy_TEXT: "Datenschutzrichtlinie",
    privacy_TEXT: "Datenschutzbestimmungen",
  },
  readMore_TEXT: "Mehr lesen",
  signUp_TEXT: "Anmelden",
  submit_TEXT: "Absenden",
  apis_TEXT: {
    error_TEXT:
      "Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.",
  },
  required_TEXT: "Erforderlich",
  message_TEXT: "Nachricht",
  loginWithGoogle_TEXT: "Mit Google fortfahren",
  notSelected_TEXT: "Nicht ausgewählt",
  internetIssue_TEXT:
    "Verbindung konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.",
  connectionTimeOut_TEXT:
    "Verbindungstimeout. Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.",
  errorOccured_TEXT:
    "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
  copiedToClipBoard_TEXT: "In die Zwischenablage kopiert!",
  googleSignInError_TEXT: "Fehler bei der Google-Anmeldung",
  thankYouForRegistration_TEXT: "Vielen Dank für Ihre Anmeldung!",
  close_TEXT: "Schließen",
  swipeTheMarker: "Ziehen Sie den Marker",
  ragLoaderTexts: [
    "Frage wird überprüft und relevante Daten werden abgerufen...",
    "Antwort wird generiert...",
    "Antwort wird überprüft...",
  ],
  generateText: "Fragen",
  answerText: "Antwort",
  questionText: "Frage",
  referenceArticle: "Referenzartikel",
  enterQuestion: "Frage eingeben",
  suggestedQuestions: "Vorgeschlagene Fragen",
};

const HE_Language = {
  engDesc_TEXT: "בחרו את האפשרות הטובה ביותר",
  poweredBy_TEXT: "מופעל על ידי",
  name_TEXT: "שם",
  enterFullname_TEXT: "הכנס את שמך המלא",
  fullName_TEXT: "שם מלא",
  email_TEXT: "אימייל",
  enterEmail_TEXT: "הכנס את האימייל שלך",
  emailNotValid_TEXT: "האימייל אינו תקין!",
  newsletterSource_TEXT: "לאן תרצה לקבל את הניוזלטר שלך",
  back_TEXT: "חזרה",
  next_TEXT: "הבא",
  termsAndConditions_TEXT: {
    main_TEXT: "אתה מסכים שקראת וקיבלת את ה",
    service_TEXT: "תנאי השירות",
    privacy_TEXT: "מדיניות הפרטיות",
  },
  readMore_TEXT: "קרא עוד",
  signUp_TEXT: "הרשמה",
  submit_TEXT: "שלח",
  apis_TEXT: {
    error_TEXT: "אירעה שגיאה! אנא נסה שוב מאוחר יותר.",
  },
  required_TEXT: "נדרש",
  message_TEXT: "הודעה",
  loginWithGoogle_TEXT: "המשך עם גוגל",
  notSelected_TEXT: "לא נבחר",
  internetIssue_TEXT: "לא הצלחנו ליצור חיבור. אנא בדוק את חיבור הרשת ונסה שוב.",
  connectionTimeOut_TEXT: "זמן החיבור נגמר. אנא בדוק את חיבור הרשת ונסה שוב.",
  errorOccured_TEXT: "אירעה שגיאה. אנא נסה שוב מאוחר יותר.",
  copiedToClipBoard_TEXT: "הועתק ללוח!",
  googleSignInError_TEXT: "שגיאה במהלך הכניסה עם גוגל",
  thankYouForRegistration_TEXT: "תודה שנרשמת!",
  close_TEXT: "סגור",
  swipeTheMarker: "החלק את הסמן",
  ragLoaderTexts: [
    "בודק שאלה ומביא מידע רלוונטי...",
    "מייצר תגובה...",
    "מאמת תגובה...",
  ],
  generateText: "צור",
  answerText: "תשובה",
  questionText: "שאלה",
  referenceArticle: "מאמרי הפניה",
  enterQuestion: "הכנס שאלה",
  suggestedQuestions: "שאלות מוצעות",
};

export {
  US_Language,
  ES_Language,
  NL_Language,
  LV_Language,
  UA_Language,
  DA_Language,
  CAT_Language,
  EST_Language,
  CZ_Language,
  DE_Language,
  HE_Language,
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getTextColor from "../../../controllers/getTextColor";
import ctaFinder from "../../../../constants/ctas/ctaFinder";
import Icon from "../../../components/Icon";
import { setCrouselCurrentCta } from "../../../../redux/slices/appSlice";
import useCtaViewHandler from "../../../../presentation/ctas/reusable/controllers/useCtaViewHandler";
import useLinkController from "../../../../presentation/ctas/link/controllers/useLinkController";

function Content() {
  const [crouselCtas, setCrouselCtas] = useState([]);
  const { handleCtaView } = useCtaViewHandler();
  const { handleClickLink } = useLinkController();

  const dispatch = useDispatch();
  const readMore_TEXT = useSelector(
    (state) => state.language.data.readMore_TEXT
  );
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const linkedActions = useSelector((state) => state?.app.linkedActions);

  useEffect(() => {
    if (linkedActions?.length > 0) {
      setCrouselCtas(linkedActions);
    } else {
      setCrouselCtas(ctas);
    }
  }, [ctas, linkedActions]);

  return (
    <div className="ctaCarouselContent customScrollBar">
      {crouselCtas?.map((data, index) => {
        const { background, actionData, title, description, _id } = data;

        const actionType = actionData?.actionType;
        const buttonText = actionData?.buttonText;
        const signUpButtonText = actionData?.signUpButtonText;
        const type = background?.type;
        const color = background?.color;
        const imageURL = background?.imageURL;
        const isColor = type === "color";
        const isImage = type === "image";
        const isNoBg = !isImage && !isColor;
        const textColor = "white";
        let ctaType = ctaFinder(actionType)?.name;
        const isLinkCta = ctaType === "Affiliate Link";
        const isLinkWithColor = isLinkCta && isColor;

        if (isLinkCta) {
          ctaType = readMore_TEXT;
        }

        return (
          <div
            key={index}
            className="ctaCarouselCard"
            style={{
              ...(color && {
                backgroundColor: color,
              }),
              ...(imageURL && {
                backgroundImage: `url(${imageURL})`,
              }),
              ...(isNoBg && {
                backgroundImage: `url('/bridged_banner.png')`,
              }),
              ...(isLinkWithColor && {
                backgroundColor: `var(--primary-Color)`,
              }),
            }}
          >
            <div
              className="ctaCarouselCardBody"
              style={{
                color: textColor || "white",
              }}
              onClick={() => {
                if (isLinkCta) {
                  handleCtaView(_id);
                  handleClickLink({
                    actionId: _id,
                    takeActionData: {
                      actionType: "link",
                    },
                  });
                  window.open(actionData?.buttonLink, "_blank");
                } else {
                  const actionIndex = ctas.findIndex(
                    (action) => action._id === _id
                  );
                  if (actionIndex >= 0) {
                    dispatch(setCrouselCurrentCta(actionIndex));
                  }
                }
              }}
            >
              {/* <div className="chip">
                <p>{ctaType}</p>
              </div> */}
              <div />
              <div className="ctaCarouselCardBodyContent">
                <h3
                  style={{
                    marginBottom: "var(--mpr-3)",
                  }}
                >
                  {title}
                </h3>
                {/* <p
                  style={{
                    marginBottom: "var(--mpr-3)",
                  }}
                  className="opacity05 secondLine"
                >
                  {description}
                </p> */}
                <span>
                  <p>{buttonText || signUpButtonText || readMore_TEXT}</p>
                  <Icon
                    name="ri-arrow-right-s-line"
                    style={{
                      fontSize: "20px",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Content;
